.contact-info {
    margin-top: 3em;
    &-list {
        line-height: 1.4;
        li {
            margin-bottom: 0.5em;
        }
        .label {
            &::after {
                content: '/';
                display: inline-block;
                padding: 0 0.5em;
            }
        }
        .ic {
            color: $brand-primary5;
            font-size: 0.8em;
            margin-left: 0.5em;
        }
        a {
            display: inline;
        }
        a:hover {
            color: $brand-primary;
            .ic {
                color: $brand-primary;
            }
        }
    }
}
.contact-title {
    color: $brand-primary;
    @include fz(30,18);
    font-weight: 700;
    line-height: 1.4;
    margin: 0 0 0.75em;
}
.contact-pic {
    margin-top: 2em;
    display: flex;
    .qrcode {
        text-align: center;
        color: $brand-primary;
        @include fz(16,18);
        font-weight: 700;
        margin-right: 0.5em;
        img {
            display: block;
            border: 1px solid $brand-primary6;
        }
    }
}

@media (min-width: $screen-sm-min) {
    .contact-banner {
        .banner-txt {
            padding-right: 1em;
        }
    }
    .contact-reminder {
        position: relative;
        line-height: (30 / 18);
        &::before {
            content: '';
            display: block;
            position: absolute;
            left: -2em;
            top: 0.75em;
            height: 300px;
            width: 1em;
            border: 1px solid $brand-primary6;
            border-width: 1px 0 0 1px;
        }
    }
    .contact-form {
        position: relative;
        &::before {
            content: '';
            display: block;
            position: absolute;
            right: -2em;
            bottom: 1.5em;
            height: 300px;
            width: 1em;
            border: 1px solid $brand-primary6;
            border-width: 0 1px 1px 0;
        }
    }
}
@media (min-width: $screen-md-min) {
    .contact-banner {
        .banner-txt {
            padding-right: 2.5em;
        }
    }
    .contact-main {
        .container {
            padding-left: 50px;
            padding-right: 40px;
        }
    }
    .contact-reminder {
        &::before {
            left: -50px;
            width: 25px;
        }
    }
    .contact-form {
        &::before {
            right: -40px;
            width: 25px;
        }
    }
    .contact-reminder,
    .contact-info {
        float: left;
        width: percentage($number: 430 / 1230);
    }
    .contact-form {
        float: right;
        width: percentage($number: 745 / 1230);
    }
}
@media (min-width: $screen-lg-min) {
    .contact-main {
        background: url('../img/contact_deco.png') no-repeat 0 100%;
        background-size: percentage($number: 306 / 1920) auto;
        .container {
            max-width: 1390px;
            padding-left: 90px;
            padding-right: 70px;
        }
    }
    .contact-reminder {
        &::before {
            left: -90px;
            width: 48px;
        }
    }
    .contact-form {
        &::before {
            right: -70px;
            width: 48px;
        }
    }
}
@media (min-width: $screen-xl-min) {
    .contact-banner {
        .banner-txt {
            padding-right: 2.5em;
        }
    }
}
@media (min-width: $screen-xxl-min) {
  
}