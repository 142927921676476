body {
  .pswp {
    width: auto;
    height: auto;
    right: 0;
    bottom: 0;
    img {
      max-height: none;
    }
  }
  .pswp__bg {
    background: rgba(#000,0.95);
  }
  .pswp__button,
  .pswp__button--arrow--left:before,
  .pswp__button--arrow--right:before {
    background-image: url(../img/default-skin.png);
  }
  .pswp__button--arrow--left:before,
  .pswp__button--arrow--right:before {
    background-size: 396px 132px;
    width: 45px;
    height: 48px;
    top: 50%;
    margin-top: -24px;
  }
  .pswp__button--arrow--left:before {
    background-position: -207px -66px;
  }
  .pswp__button--arrow--right:before {
    background-position: -141px -66px;
  }
  .pswp--svg .pswp__button--arrow--left,
  .pswp--svg .pswp__button--arrow--right {
    background: 0 0;
  }
  .pswp__button--thumbs,
  .pswp--svg .pswp__button--thumbs {
    background: url(../img/pswp_thumbs.png) no-repeat 50% 50%;
  }
  .pswp__caption__center {
    max-width: 90%;
    border-top: 1px solid #fff;
    margin: 2em auto;
  }
  .pswp__thumbs {
    position: fixed;
    z-index: 1500;
    bottom: -100;
    right: 0;
    width: 100%;
    height: 100px;
    overflow-x: auto;
    overflow-y: hidden;
    background: #fff;
    text-align: center;
    .list {
      padding: 5px;
      white-space: nowrap;
      li {
        display: inline-block;
        vertical-align: top;
        width: 100px;
        padding: 5px;
        .img {
          background: 50% 50% no-repeat;
          background-size: cover;
          padding-top: percentage(3 / 4);
          border: 3px solid #fff;
        }
      }
      li.active {
        .img {
          border-color: $brand-primary;
        }
      }
    }
  }
}
.pswp__thumbs--open {
  .pswp {
    bottom: 100px;
  }
  .pswp__thumbs {
    bottom: 0;
  }
}
@media (-webkit-min-device-pixel-ratio: 1.1), (-webkit-min-device-pixel-ratio: 1.09375), (min-resolution: 105dpi), (min-resolution: 1.1dppx) {
  body {
    .pswp--svg .pswp__button,
    .pswp--svg .pswp__button--arrow--left:before,
    .pswp--svg .pswp__button--arrow--right:before {
      background-image: url(../img/default-skin.svg);
    }
    .pswp--svg .pswp__button--arrow--left,
    .pswp--svg .pswp__button--arrow--right {
      background: 0 0;
    }
    .pswp--svg .pswp__button--thumbs {
      background: url(../img/pswp_thumbs.png) no-repeat 50% 50%;
    }
  }
}
@media (min-width: $screen-sm-min) {
  body {
    .pswp {
      right: 0;
      bottom: 0;
    }
    .pswp__thumbs {
      width: 210px;
      height: 100%;
      right: -210px;
      overflow-x: hidden;
      overflow-y: auto;
      text-align: left;
      .list {
        white-space: normal;
        li {
          width: 50%;
        }
      }
    }
  }
  .pswp__thumbs--open {
    .pswp {
      right: 210px;
      bottom: 0;
    }
    .pswp__thumbs {
      right: 0;
      bottom: 0;
    }
  }
}
