.header {
  .site-title {
    background: #fff;
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 18px;
    box-shadow: 0 0 10px rgba(#000,0.25);
    font-size: 1em;
    transition: 0.3s;
    .logo {
      @include txt-hide;
      vertical-align: top;
      background: url(../img/logo.png) no-repeat;
      background-size: contain;
      &,
      .header-fixed & {
        width: 190px;
        height: 44px;
      }
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
.nav {
  background: $brand-primary;
  color: #fff;
  position: fixed;
  z-index: -100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  transition: 0.3s;
  padding-top: 80px;
  .nav-open & {
    opacity: 1;
    z-index: 150;
    overflow-y: auto;
    overflow-x: hidden;
    pointer-events: visible;
  }
  &-open {
    overflow: hidden;
  }
  &-toggle {
    position: fixed;
    z-index: 200;
    right: 15px;
    top: 10px;
    width: 60px;
    height: 60px;
    transition: 0.3s;
    padding: 0;
    .ic-bar {
      width: 25px;
      height: 2px;
      background: $brand-primary;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -1px;
      margin-left: -12.5px;
      transition: 0.3s;
      &:nth-child(1) {
        transform: translateY(-9px);
        .nav-open & {
          transform: translateY(0);
          opacity: 0;
        }
      }
      &:nth-child(2) {
        transform: translateY(9px);
        .nav-open & {
          transform: translateY(0);
          opacity: 0;
        }
      }
    }
  }
  &-inner {
    position: relative;
    min-height: 100%;
    padding: 45px 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }
  .menu {
    @include fz(18px);
    margin-bottom: 60px;
    > li {
      margin-bottom: 0.5em;
      > a {
        position: relative;
        padding: 0.5em 4em;
        line-height: 1.5;
        font-weight: 700;
        max-width: 400px;
        margin: 0 auto;
        span {
          display: inline-block;
        }
        .tw {
          margin-right: 0.5em;
        }
        .en {
          @include fz(11,18);
          text-transform: uppercase;
        }
      }
    }
    > li:hover,
    > li.current {
      background: $brand-primary5;
    }
  }
  &-footer {
    position: relative;
    padding: 0 30px;
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    color: rgba(#fff,0.7);
  }
}

@media (min-width: $screen-sm-min) {
  .nav {
    .menu {
      > li {
        > a {
          max-width: 780px;
        }
      }
    }
    &-footer {
      max-width: 780px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
@media (min-width: $screen-md-min) {
  .header {
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    right: 0;
    background: url('../img/header_bg.jpg') no-repeat 50% 50%;
    background-size: cover;
    padding: 1.5em 0;
    border-bottom: 1px solid $brand-primary5;
    .site-title {
      position: static;
      background: none;
      box-shadow: none;
      float: left;
      padding: 9px 20px;
      text-align: center;
    }
  }
  .nav {
    position: relative;
    background: none;
    color: $text-color;
    padding-top: 0;
    margin-left: 240px;
    &,
    .nav-open & {
      overflow: visible;
      pointer-events: visible;
      opacity: 1;
    }
    &-open {
      overflow: auto;
    }
    &-toggle {
      display: none;
    }
    &-inner {
      padding: 0;
    }
    .menu {
      margin-bottom: 0;
      > li {
        display: inline-block;
        vertical-align: top;
        margin-left: 0.5em;
        margin-bottom: 0;
        > a {
          padding: 0.5em 0 0.5em 1.5em;
          max-width: none;
          border-bottom: 1px solid transparent;
          .en {
            display: block;
          }
          &:before {
            content: '';
            display: block;
            background: url('../img/dot.png') no-repeat;
            width: 20px;
            height: 20px;
            position: absolute;
            left: 0;
            top: 0.7em;
            opacity: 0;
            transition: 0.3s;
          }
        }
      }
      > li:hover,
      > li.current {
        background: none;
      }
      > li > a:hover,
      > li.current > a {
        background: none;
        border-bottom-color: $brand-primary5;
        color: $brand-primary;
        &:before {
          opacity: 1;
        }
      }
    }
    &-footer {
      display: none;
    }
  }
}
@media (min-width: $screen-lg-min) {
  .header {
    .site-title {
      width: 300px;
    }
  }
  .nav {
    margin-left: 350px;
  }
}
@media (min-width: $screen-xl-min) {
  .header {
    padding: 34px 0;
    .site-title {
      width: percentage($number: 300 / 1920);
    }
  }
  .nav {
    margin-left: percentage($number: 350 / 1920);
    &-inner {
      padding: 0.5em 0;
    }
    .menu {
      > li {
        > a {
          .tw {
            margin-right: 0;
          }
          .en {
            display: inline-block;
          }
        }
      }
    }
  }
}
@media (min-width: $screen-xxl-min) {
  .nav {
    .menu {
      > li {
        margin-left: 2em;
      }
    }
  }
}
