.main-content {
  &.has-sideL,
  &.has-sideR {
    padding: 0;
    .content {
      padding: 2.5em 0 3em;
    }
  }
}
.side {
  &.sideL {
    border: 1px solid $brand-primary6;
    border-width: 0 0 1px;
    padding: 1.5em 0;
  }
  &.sideR {
    background: rgba(#000,0.1);
    border: 1px solid $brand-primary6;
    border-width: 1px 0 0;
    padding: 1.5em ($grid-gutter-width*0.5);
  }
  &-nav {
    text-align: right;
    > li {
      padding: 0.25em 0;
      > a {
        position: relative;
        padding-right: 3em;
        &:after {
          content: '';
          display: block;
          border-top: 1px solid $brand-primary6;
          width: 1.8em;
          position: absolute;
          right: 0;
          top: 0.8em;
          opacity: 0;
          transition: 0.3s;
        }
      }
    }
    > li > a:hover,
    > li.current > a {
        color: $brand-primary;
        text-shadow: 0 0 1px $brand-primary;
        &:after  {
          opacity: 1;
        }
    }
  }
  &-sect {
    margin-bottom: 2em;
    &.px-15 {
      padding: 0 1.5em;
    }
  }
  &-page {
    color: $brand-primary4;
    .bt {
      display: inline-block;
      padding: 0 1.5em;
      line-height: 50px;
      .ic, .txt {
        display: inline-block;
        vertical-align: middle;
      }
      .ic {
        margin-right: 0.5em;
        &-back {
          font-size: 1.2em;
          position: relative;
          margin-top: -0.15em;
        }
      }
      .txt {
        text-align: center;
        min-width: 4em;
      }
      &:hover {
        color: #fff;
        background: $brand-primary;
      }
    }
  }
  &-share {
    color: $brand-primary4;
    .a2a_default_style {
      .bt {
        display: block;
        float: none;
        text-align: left;
        line-height: 50px;
        padding: 0 1.5em;
        .ic {
          font-size: 1.5em;
          vertical-align: middle;
          position: relative;
          top: -0.1em;
          margin-right: 0.5em;
        }
        &:hover {
          color: #fff;
        }
        &.a2a_button_facebook {
          &:hover {
            background-color: $brand-facebook;
          }
        }
        &.a2a_button_line {
          &:hover {
            background-color: $brand-line;
          }
        }
      }
    }
  }
  &-title {
    color: $brand-primary;
    @include fz(20px);
    font-weight: 700;
    margin: 0 0 0.2em;
  }
  &-latest {
    .item {
      border-bottom: 1px solid $brand-primary6;
      margin-bottom: 0.75em;
    }
    .box {
      padding: 0.75em 0;
      .date {
        @include fz(18px);
        font-family: $font-family-sans-serif2;
        line-height: 1.25;
      }
    }
    .box:hover {
      .title {
        color: $brand-primary;
        font-weight: 700;
      }
    }
  }
  &-logout {
    padding: 0 2.25em;
    text-align: right;
    .bt-logout {
      line-height: 50px;
    }
  }
}


@media (min-width: $screen-sm-min) {

}
@media (min-width: $screen-md-min) {
  .main-content {
    &.has-sideL,
    &.has-sideR {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      .content {
        flex: 1;
        padding: 4em 0 6em;
      }
    }
  }
  .side {
    &.sideL {
      flex: 0 0 280px;
      width: 280px;
      border-width: 0 1px 0 0;
      padding: 6em 0 6em 1.5em;
    }
    &.sideR {
      flex: 0 0 100%;
      width: 100%;
      padding: 5em 3em;
    }
    &-nav {
      @include fz(18px);
      > li {
        padding: 0.5em 0;
      }
    }
  }
}
@media (min-width: $screen-lg-min) {
  .side {
    &.sideR {
      flex: 0 0 percentage($number: 560 / 1920);
      width: percentage($number: 560 / 1920);
      border-width: 0 0 0 1px;
    }
  }
}
