.field {
  display: block;
  width: 100%;
  height: $input-height;
  padding: 0;
  border: 0;
  background: none;
  &:focus {
    outline: none;
  }
  @include field-placeholder {
    color: $input-color-placeholder;
  }
}
textarea.field {
  height: auto;
  line-height: 1.5;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}
.has-error .field {
  border-color: $brand-danger;
}
.has-error,
.has-error .field-label {
  color: $brand-danger;
}
.required-hint {
  color: #ed1c24;
}
.hint {
  line-height: 1.2;
  color: $brand-primary3;
  margin-bottom: 1.5em;
}
.field-group {
  position: relative;
  line-height: $input-height;
  margin-bottom: 20px;
  .field-label {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 5.5em;
    text-align: right;
    padding-left: 0.5em;
    .required {
      color: $brand-danger;
      position: absolute;
      left: 0.75em;
      top: 0;
    }
  }
  .field-box {
    position: relative;
    padding-left: 7em;
    border: 1px solid $input-border;
    background: #fff;
    .field {
      padding-right: 1.5em;
    }
    &.pl0 {
      padding-left: 1.75em;
      padding-right: 1.5em;
    }
    .agree-label {
      display: inline-block;
      vertical-align: top;
      padding: 0.8em 0;
      line-height: 1.4;
      a {
        color: $brand-primary-light;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &:focus-within {
      border-color: $brand-primary;
    }
  }
}
.sex-radio {
  position: absolute;
  right: 0;
  bottom: 0;
}
.captcha-img {
  position: absolute;
  right: 7px;
  bottom: 7px;
}
.label-box {
  label {
    display: inline-block;
    margin-right: 1em;
  }
}
.gender-control {
  position: absolute;
  right: 0;
  top: 0;
  .inline-label {
    display: inline-block;
    margin-right: 1em;
  }
}
.field-btns {
  padding-top: 20px;
  @include fz(18px);
  text-align: right;
  &.tac {
    text-align: center;
  }
  .bt {
    margin: 0 0 4px 2px;
  }
}
.reminder {
  padding: 0 0 1em;
  p {
      margin-bottom: 1em;
  }
  .required {
      color: #ee393e;
  }
}

@media (min-width: $screen-sm-min) {
  .field-group {
    .field-box {
      padding-left: 7.5em;
    }
  }
  .field-btns {
    @include fz(20px);
  }
}
@media (min-width: $screen-lg-min) {
  .reminder {
    @include fz(18px);
  }
}
