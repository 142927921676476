
// Body
$body-bg: #fff;

// Brands
$brand-primary: #103953;
$brand-primary-light: #053b57;
$brand-primary2: #222;
$brand-primary3: #333;
$brand-primary4: #666;
$brand-primary5: #999;
$brand-primary6: #ccc;
$brand-success: #5cb85c;
$brand-info:    #5bc0de;
$brand-warning: #f0ad4e;
$brand-danger:  #ed1c24;

$brand-facebook: rgb(24, 119, 242);
$brand-line: rgb(0, 195, 0);

$icon-font-family: 'kunchen_icons';
// $font-family-custom: "DaunPenh", serif;
$font-family-custom: 'Noto Sans TC', sans-serif;
$font-family-sans-serif: '微軟正黑體', 'Microsoft JhengHei', Arial, "Helvetica Neue", Helvetica, sans-serif;
$font-family-sans-serif2: Arial, "Helvetica Neue", Helvetica, sans-serif;
$font-family-serif: 'Noto Serif TC', 'Times New Roman', Times, 'source-han-serif-tc', 'PMingLiU', 'SimSun', '思源宋體', Georgia, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;

$font-family-base: $font-family-sans-serif;
$font-size-base: 16px;
$line-height-base: (29 / 16);
$text-color: $brand-primary3;
$letter-spacing-base: 0;

$input-bg: #fff;
$input-border: #ccc;
$input-border-focus: $brand-primary;
$input-color-placeholder: #999;
$input-height: 48px;

$screen-xxl: 1680px;
$screen-xl: 1440px;
$screen-lg: 1200px;
$screen-md: 992px;
$screen-sm: 768px;
$screen-xs: 480px;

$screen-xxl-min: $screen-xxl;
$screen-xl-min: $screen-xl;
$screen-lg-min: $screen-lg;
$screen-md-min: $screen-md;
$screen-sm-min: $screen-sm;
$screen-xs-min: $screen-xs;

$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);
$screen-lg-max:              ($screen-xl-min - 1);
$screen-xl-max:              ($screen-xxl-min - 1);

$grid-columns: 12;
$grid-gutter-width: 50px;
$container-tablet:             (720px + $grid-gutter-width);
$container-desktop:            (940px + $grid-gutter-width);
$container-large-desktop:      (1140px + $grid-gutter-width);
$container-sm:                 $container-tablet;
$container-md:                 $container-desktop;
$container-lg:                 $container-large-desktop;

$table-cell-padding: 8px;
$table-bg:           transparent !default;
$table-bg-striped:    #f5f5f5 !default;
$table-bg-hover:     #f0f0f0 !default;
$table-bg-active:    $table-bg-hover !default;
$table-border-color: #ddd !default;
