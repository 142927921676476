.about-layout1 {
  .pic {
    position: relative;
    height: 400px;
    background: 50% 50% no-repeat;
    background-size: cover;
  }
  .logo {
    position: absolute;
    width: 153px;
    right: 25px;
    top: 25px;
  }
  .txt {
    position: relative;
    text-align: center;
  }
  .title {
    color: #fff;
    @include fz(40px);
    font-weight: 700;
    line-height: 1.25;
    text-shadow: 0 2px 10px #000;
    margin-top: -1.875em;
    margin-bottom: 0;
  }
  .desc {
    position: relative;
    &:before {
      content: '';
      display: block;
      border-top: 1px solid $brand-primary5;
      width: 170px;
      margin: 2.5em auto 2em;
    }
  }
}
.about-layout2 {
  .pics {
    .pic {
      margin-bottom: 1.5em;
    }
  }
}
.about-title {
  font-size: 2em;
  font-weight: 700;
  line-height: 1.5;
  margin: 0 0 0.5em;
  color: $brand-primary;
}

@media (min-width: $screen-sm-min) {
  .about-layout1 {
    .title {
      margin-top: -3em;
    }
    .desc {
      margin: 50px;
      &:before {
        position: absolute;
        left: -45px;
        bottom: 0.5em;
        margin: 0;
      }
      &:after {
        content: '';
        display: block;
        border: 1px solid $brand-primary5;
        border-width: 0 1px 1px 0;
        width: 74px;
        height: 52px;
        position: absolute;
        right: 0;
        bottom: 0.5em;
      }
    }
  }
  .about-layout2 {
    position: relative;
    .pics {
      display: flex;
      margin: 0 -5px;
      .pic {
        flex: 1;
        padding: 0 5px;
      }
    }
  }
}
@media (min-width: $screen-md-min) {
  .about-layout2 {
    max-width: 1300px;
    margin: 0 auto;
    @include clearfix;
    .pics {
      float: left;
      width: percentage($number: 620 / 1300);
    }
    .txt {
      float: right;
      width: percentage($number: 660 / 1300);
      padding: 0 2em;
    }
  }
}
@media (min-width: $screen-lg-min) {
  .about-layout1 {
    .logo {
      top: 65px;
      right: 65px;
    }
    .title {
      @include fz(45,18);
    }
    .pic {
      height: 560px;
    }
    .desc {
      margin-top: 60px;
      margin-left: percentage($number: 115 / 1427);
      margin-right: percentage($number: 115 / 1427);
    }
  }
  .about-layout2 {
    .txt {
      padding-top: 1em;
    }
  }
}
@media (min-width: $screen-xl-min) {
  .about-layout1,
  .about-layout2 {
    @include fz(18px);
  }
}
