.classic-list {
  margin-bottom: 3em;
  .box {
    margin: 0 auto;
    padding: 1em 0;
    position: relative;
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 5px;
      background: $brand-primary5;
    }
    &::before {
      top: 0;
      height: 80px;
    }
    &::after {
      top: 120px;
      bottom: 0;
    }
    .year {
      position: absolute;
      top: 80px;
      color: $brand-primary;
      @include fz(20,18);
      line-height: 40px;
      font-family: $font-family-sans-serif2;
      width: 72px;
      text-align: center;
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        display: block;
        width: 1em;
        border-top: 1px solid $brand-primary6;
      }
    }
    .pic {
      display: inline-block;
      vertical-align: top;
      position: relative;
      border: 1px solid $brand-primary5;
      max-width: 250px;
      img {
        width: 100%;
      }
      &:after {
        content: '';
        display: block;
        background: rgba($brand-primary,0.7) url('../img/more.png') 50% 50% no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        transition: 0.3s;
      }
    }
    .txt {
      padding: 1em;
    }
    .title {
      color: $brand-primary;
      @include fz(30,18);
      font-weight: 700;
      line-height: 1.25;
      margin: 0 0 0.25em;
    }
    .desc {
      line-height: (30 / 18);
    }
  }
  .box {
    padding-left: 4.5em;
    margin-left: 1em;
    &:before,
    &:after {
      left: -2.5px;
    }
    .year {
      left: -36px;
      &::after {
        left: 100%;
      }
    }
  }
  .box:hover {
    .pic {
      border-color: $brand-primary;
      &:after {
        opacity: 1;
      }
    }
  }
}

@media (min-width: $screen-sm-min) {
  .classic-list {
    .box {
      padding-bottom: 0;
      .year {
        &::after {
          width: 2em;
        }
      }
    }
    .item:nth-child(2n+1) {
      .box {
        padding-left: 6em;
        margin-left: 50%;
        text-align: left;
        &:before,
        &:after {
          left: -2.5px;
        }
        .year {
          left: -36px;
          &::after {
            left: 100%;
          }
        }
      }
    }
    .item:nth-child(2n) {
      .box {
        text-align: right;
        padding-left: 0;
        padding-right: 6em;
        margin-left: 0;
        margin-right: 50%;
        &:before,
        &:after {
          left: auto;
          right: -2.5px;
        }
        .year {
          left: auto;
          right: -36px;
          &::after {
            left: auto;
            right: 100%;
          }
        }
      }
    }
  }
}
@media (min-width: $screen-md-min) {
}
@media (min-width: $screen-lg-min) {
  .classic-list {
    .box {
      display: flex;
      align-items: flex-end;
      .pic, .txt {
        flex: 1;
      }
      .txt {
        padding: 1em 2em;
      }
    }
    .item:nth-child(2n+1) {
      .box {
        .txt {
          padding-right: 0;
        }
      }
    }
    .item:nth-child(2n) {
      .box {
        flex-direction: row-reverse;
        .txt {
          padding-left: 0;
        }
      }
    }
  }
}
@media (min-width: $screen-xl-min) {
  .classic-list {
    .box {
      @include fz(18px);
    }
  }
}
