.banner {
  &-title {
    position: absolute;
    left: 1.2em;
    right: 1.2em;
    bottom: 1em;
    @include fz(27px);
    color: #fff;
    text-align: right;
    line-height: 1;
    margin: 0;
    text-shadow: 2px 2px 10px rgba(#000,0.6);
    span {
      display: block;
    }
    .txt1 {
      font-weight: 700;
      letter-spacing: 0.2em;
      margin-bottom: 0.5em;
    }
    .txt2 {
      font-family: $font-family-sans-serif2;
      @include fz(54,27);
      opacity: 0.6;
    }
  }
  &-pic {
    position: relative;
    .bg {
      background: 50% 50% no-repeat;
      background-size: cover;
      &:before {
        content: '';
        display: block;
        padding-top: 500px;
      }
      &.pc {
        display: none;
      }
    }
  }
  &-txt {
    writing-mode: vertical-rl;
    border: 1px solid $brand-primary5;
    border-width: 1px 0;
    background: url('../img/pattern.jpg');
    padding: 1em;
    width: 100%;
    @include fz(27px);
    .txt1 {
      font-family: $font-family-custom;
      color: $brand-primary;
      span {
        display: inline-block;
      }
    }
    .txt2 {
      @include fz(16,27);
      margin-right: 1em;
    }
  }
}

@media (min-width: $screen-xs-min) {
}
@media (min-width: $screen-sm-min) {
  .banner {
    position: relative;
    border-bottom: 1px solid $brand-primary5;
    &-pic {
      position: absolute;
      left: 320px;
      right: 0;
      top: 0;
      bottom: 0;
      .bg {
        width: 100%;
        height: 100%;
        &:before {
          padding: 0;
        }
      }
      .bg {
        &.pc {
          display: block;
        }
        &.m {
          display: none;
        }
      }
    }
    &-txt {
      width: 320px;
      border-width: 0 1px 0 0;
      padding: 2.5em 2.5em 2.5em 1.5em;
      height: 520px;
      position: relative;
      &:before,
      &:after {
        content: '';
        display: block;
        background: url('../img/dot.png') no-repeat;
        width: 20px;
        height: 20px;
        position: absolute;
        right: 1.5em;
        pointer-events: none;
      }
      &:before {
        top: 1.5em;
      }
      &:after {
        bottom: 1.5em;
      }
    }
  }
}
@media (min-width: $screen-md-min) {
  .banner {
    &-title {
      @include fz(36px);
      bottom: 0.8em;
      .txt2 {
        @include fz(100,43);
      }
    }
    &-pic {
      left: 380px;
    }
    &-txt {
      width: 380px;
      height: 600px;
      padding-top: 4em;
      padding-right: 3.5em;
    }
  }
}
@media (min-width: $screen-lg-min) {
}
@media (min-width: $screen-xl-min) {
  .banner {
    &-title {
      @include fz(43px);
    }
    &-pic {
      left: 435px;
    }
    &-txt {
      width: 435px;
      height: 700px;
      padding-top: 4.8em;
      padding-right: 3.33em;
      @include fz(30px);
      .txt2 {
        @include fz(18,30);
        margin-right: 1.2em;
      }
      &:before,
      &:after {
        right: 1.8em;
      }
      &:before {
        top: 1.8em;
      }
      &:after {
        bottom: 1.8em;
      }
    }
  }
}
@media (min-width: $screen-xxl-min) {
}