.hot-list {
  .box {
    max-width: 600px;
    margin: 0 auto 4em;
  }
  .pic {
    img {
      transition: 0.3s;
      box-shadow: 0 0 10px rgba(#000,0.3);
    }
    a {
      position: relative;
      display: block;
      &:after {
        content: '\e907';
        @include icon-font;
        font-size: 30px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba($brand-primary,0.7);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        transition: 0.3s;
      }
    }
    a:hover {
      &:after {
        opacity: 1;
      }
    }
  }
  .txt1 {
    position: relative;
    padding: 0 0 1px 2em;
    margin-left: 1em;
    &:before {
      content: '';
      display: block;
      border: 1px solid $brand-primary6;
      border-width: 1px 0 0 1px;
      width: 1em;
      position: absolute;
      top: 0.5em;
      bottom: 0;
      left: 0;
    }
  }
  .year {
    color: $brand-primary;
    @include fz(20px);
    line-height: 1;
    font-family: $font-family-sans-serif2;
  }
  .title {
    color: $brand-primary;
    @include fz(45px);
    font-weight: 700;
    line-height: 1.25;
    margin: 0.2em 0 0.25em;
  }
  .desc {
    @include fz(18px);
    line-height: (30 / 18);
  }
  .txt2 {
    margin: 0 1em;
    position: relative;
    padding: 1em 2em 0 0.5em;
    &:before {
      content: '';
      display: block;
      border: 1px solid $brand-primary6;
      border-width: 0 1px 1px 0;
      width: 1em;
      position: absolute;
      top: 0;
      bottom: 0.75em;
      right: 0;
    }
  }
  .title2 {
    color: $brand-primary;
    @include fz(30px);
    font-weight: 700;
    line-height: (40 / 30);
    margin: 0 0 0.25em;
  }
  .desc2 {
    line-height: (26 / 16);
    margin-bottom: 2.5em;
  }
  .info {
    margin-right: -2em;
    margin-bottom: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid $brand-primary6;
    &-title {
      font-weight: 700;
      display: flex;
      align-items: center;
      line-height: 1.25;
      margin: 0 0 0.75em;
      &:after {
        content: '';
        display: block;
        border-top: 1px solid $brand-primary6;
        flex: 1;
        margin-left: 1em;
      }
    }
    &-item {
      padding-right: 2em;
      color: $brand-primary;
      font-weight: 700;
      > * {
        display: inline-block;
      }
      .txt-link {
        @include fz(18px);
        &.phone {
          font-family: $font-family-sans-serif2;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .btns {
    text-align: right;
    color: $brand-primary4;
    .bt {
      display: inline-block;
      padding: 0 0.25em;
      font-weight: 700;
      .ic {
        font-size: 1.3em;
        margin-right: 0.25em;
      }
      &:hover {
        color: $brand-primary;
      }
    }
  }
}

@media (min-width: $screen-sm-min) {
  
}
@media (min-width: $screen-md-min) {
  .hot-list {
    .box {
      max-width: none;
      margin-bottom: 6em;
      display: flex;
      align-items: flex-end;
    }
    .group {
      flex: 1
    }
    .txt2 {
      flex: 0 0 310px;
      margin: 0 0 0 2.5em;
      padding-top: 2.5em;
    }
  }
}
@media (min-width: $screen-lg-min) {
}
@media (min-width: $screen-xl-min) {
  .hot-list {
    .box {
      margin-bottom: 8em;
    }
    .group {
      display: flex;
    }
    .txt1 {
      flex: 0 0 235px;
      margin: 1em 1em percentage($number: 120 / 1440) 0;
    }
  }
}
@media (min-width: $screen-xxl-min) {
  
}
