.bt.gotop {
  position: absolute;
  z-index: 90;
  right: 0;
  bottom: 100%;
  color: $text-color;
  font-size: 12px;
  opacity: 0;
  transition: 0.3s;
  font-family: $font-family-sans-serif2;
  font-size: 18px;
  letter-spacing: 0.05em;
  padding: 0 1em;
  line-height: 30px;
  text-align: center;
  text-shadow: 0 0 5px #fff;
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: -6px;
    top: 0;
    bottom: -10px;
    width: 6px;
    background: $brand-primary5;
  }
  .header-fixed & {
    opacity: 1;
  }
  &:hover {
    color: $brand-primary;
  }
}
.footer {
  position: relative;
  background: $brand-primary;
  color: rgba(#fff,0.7);
  padding: 30px 0 20px;
  .container {
    position: relative;
    padding: 0 30px;
    max-width: 400px;
  }
  &-logo {
    display: none;
  }
  &-contact {
    .addr {
      display: block;
    }
    .tel {
      margin-right: 1.5em;
    }
    a {
      position: relative;
      padding-left: 2em;
      .ic {
        position: absolute;
        left: 0;
        top: 0.2em;
        font-size: 1.2em;
        color: #fff;
      }
      &:hover {
        color: #fff;
      }
    }
  }
  &-copyright {
    @include fz(15px);
    border-top: 1px solid rgba(#ddd, 0.2);
    margin-top: 0.75em;
    padding-top: 0.75em;
  }
  &-link {
    > * {
      display: inline-block;
      vertical-align: middle;
    }
    .social {
      color: #fff;
      margin-right: 2em;
      &:hover {
        text-decoration: none;
        opacity: 0.7;
      }
    }
    .author {
      @include fz(14px);
      color: rgba($brand-primary5, 0.5);
      a:hover {
        text-decoration: underline;
      }
    }
  }
}

@media (min-width: $screen-sm-min) {
  .footer {
    .container {
      max-width: 780px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:after {
        display: none;
      }
    }
    &-contact {
      .addr {
        margin-bottom: 0.5em;
      }
    }
    &-copyright {
      border-top: 0;
      padding-top: 0;
      margin-top: 0;
      margin-bottom: 0.5em;
    }
  }
}
@media (min-width: $screen-md-min) {
  .footer {
    padding: 45px 0 55px;
    .container {
      max-width: 1100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-logo {
      display: block;
    }
    &-contact {
      margin: 0 1em;
    }
  }
}
@media (min-width: $screen-lg-min) {
}
@media (min-width: $screen-xl-min) {
}
