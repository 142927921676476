.content-gallery {
  margin-top: 3em;
}
.gallery {
  figure {
    margin: 0;
    a {
      display: block;
      position: relative;
      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 5;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba($brand-primary,0.8) url(../img/zoom.png) 50% 50% no-repeat;
        opacity: 0;
        transition: 0.3s;
      }
      &:hover {
        &:before {
          opacity: 1;
        }
      }
    }
    img {
      width: 100%;
    }
    figcaption {
      display: none;
    }
  }
  &.grid {
    margin: -8px;
    .grid-sizer,
    .grid-item {
      width: 50%;
    }
    .grid-item {
      padding: 8px;
    }
  }
  &.slider {
    .slick-dots {
      @include list-reset;
      text-align: center;
      padding: 6px 0;
      li {
        display: inline-block;
        vertical-align: middle;
        button {
          @include btn-reset;
          @include txt-hide;
          width: 28px;
          height: 28px;
          position: relative;
          &:before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            margin: -5px 0 0 -5px;
            border-radius: 50%;
            background: $input-border;
            position: absolute;
            top: 50%;
            left: 50%;
          }
        }
      }
      li.slick-active {
        button {
          &:before {
            background: $brand-primary;
          }
        }
      }
    }
  }
}

@media (min-width: $screen-xs-min) {
}
@media (min-width: $screen-sm-min) {
  .gallery {
    &.grid {
      .grid-sizer,
      .grid-item {
        width: 33.33%;
      }
    }
  }
}
@media (min-width: $screen-md-min) {
  .gallery {
    &.grid {
      margin: -16px;
      .grid-sizer,
      .grid-item {
        width: 25%;
        padding: 16px;
      }
    }
    &.slider {
      .slick-dots {
        padding: 18px 0;
        li {
          button {
            width: 50px;
            height: 50px;
            &:before {
              width: 15px;
              height: 15px;
              margin: -7.5px 0 0 -7.5px;
            }
          }
        }
      }
    }
  }
  .content-gallery {
    margin-top: 4.5em;
  }
}
@media (min-width: $screen-lg-min) {
}
@media (min-width: $screen-xl-min) {
}
@media (min-width: $screen-xxl-min) {
}