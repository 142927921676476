.repair-header {
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    right: 0;
    background: url('../img/header_bg.jpg') no-repeat 50% 50%;
    background-size: cover;
    border-bottom: 1px solid $brand-primary6;
    text-align: center;
    padding: 18px;
    .site-title {
        display: inline-block;
        vertical-align: top;
        img {
            height: 44px !important;
        }
    }
}
.repair-banner {
    background: 50% 50% no-repeat;
    background-size: cover;
    padding: 3em 0;
    &-title {
        color: #fff;
        text-align: center;
        line-height: 1;
        margin: 0;
        text-shadow: 2px 2px 10px rgba(#000,0.6);
        span {
            display: block;
        }
        .txt1 {
            font-weight: 700;
            letter-spacing: 0.2em;
            margin-bottom: 0.5em;
        }
        .txt2 {
            font-family: $font-family-sans-serif2;
            font-size: 1.2em;
        }
    }
}
.repair-form {
    position: relative;
    .hint-txt {
        color: #999;
        margin-bottom: 0.5em;
    }
    .file-field {
        line-height: 1.5;
        margin: 0.25em 0;
    }
    .field-box.lha {
        line-height: 1.4;
        padding-top: 0.8em;
        padding-bottom: 0.8em;
        padding-right: 1.5em;
    }
    .agree-field {
        border: 0;
        background: none;
        .agree-label {
            padding-top: 0;
            padding-bottom: 0;
            a {
                text-decoration: underline;
                &:hover {
                  text-decoration: none;
                }
            }
        }
    }
}
.repair-body {
    .footer {
        background: url('../img/pattern.jpg');
        color: $text-color;
        &-contact {
            a {
                .ic {
                    color: inherit;
                }
            }
        }
        &-link {
            .social {
                color: inherit;
            }
            .author {
                color: rgba(#000,0.5);
            }
        }
        &-qrcode {
            display: none;
            img {
                border: 1px solid $brand-primary6;
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    .repair-banner {
        padding: 90px 0;
        &-title {
            .txt2 {
                @include fz(70,43);
            }
        }
    }
    .repair-main {
        background: url('../img/contact_deco.png') no-repeat 0 100%;
        background-size: percentage($number: 306 / 1920) auto;
        .container {
            padding: 0 100px;
        }
    }
    .repair-form {
        position: relative;
        &::before {
            content: '';
            display: block;
            position: absolute;
            left: -100px;
            top: 1em;
            height: 300px;
            width: 30px;
            border: 1px solid $brand-primary6;
            border-width: 1px 0 0 1px;
        }
        &::after {
            content: '';
            display: block;
            position: absolute;
            right: -70px;
            bottom: 1.5em;
            height: 300px;
            width: 30px;
            border: 1px solid $brand-primary6;
            border-width: 0 1px 1px 0;
        }
    }
}
@media (min-width: $screen-md-min) {
    .repair-header {
        padding: 34px;
    }
    .repair-banner {
        padding: 132px 0;
        &-title {
            @include fz(43px);
        }
    }
}
@media (min-width: $screen-lg-min) {
    .repair-main {
        .container {
            max-width: 1300px;
            padding: 0 150px;
        }
    }
    .repair-form {
        &::before {
            left: -100px;
            width: 45px;
        }
        &::after {
            right: -80px;
            width: 45px;
        }
    }
    .repair-body {
        .footer {
            .container {
                max-width: 1220px + $grid-gutter-width;
            }
            &-qrcode {
                display: block;
            }
        }
    }
}
@media (min-width: $screen-xl-min) {
    .repair-header {
        .site-title {
            img {
                height: 62px !important;
            }
        }
    }
}
@media (min-width: $screen-xxl-min) {
  
}
