.container {
  max-width: 1430px + $grid-gutter-width;
  margin: 0 auto;
  padding: 0 $grid-gutter-width*0.5;
}
.wrapper {
  padding-top: 80px;
}
.main {
  position: relative;
  &-content {
    padding: 3em 0;
  }
}
.br {
  display: block;
  height: 0;
  overflow: hidden;
}

@media (min-width: $screen-xs-min) {
}
@media (min-width: $screen-sm-min) {
  .main {
    .container {
      width: 90%;
    }
  }
  .main {
    &-content {
      padding: 4.5em 0;
    }
  }
  .br.sm {
    display: inline;
    height: auto;
    overflow: visible;
  }
}
@media (min-width: $screen-md-min) {
  .wrapper {
    padding-top: 112px;
  }
  .main {
    &-content {
      padding: 6em 0;
    }
  }
  .br.md {
    display: inline;
    height: auto;
    overflow: visible;
  }
}
@media (min-width: $screen-lg-min) {
  .br.lg {
    display: inline;
    height: auto;
    overflow: visible;
  }
}
@media (min-width: $screen-xl-min) {
  .wrapper {
    padding-top: 130px;
  }
  .br.xl {
    display: inline;
    height: auto;
    overflow: visible;
  }
}
@media (min-width: $screen-xxl-min) {
}
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  body {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
}
