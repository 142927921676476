.news-list {
  .box {
    margin-bottom: 3em;
    .pic {
      display: inline-block;
      vertical-align: top;
      border: 1px solid $brand-primary5;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba($brand-primary,0.5) url('../img/more.png') no-repeat 50% 50%;
        opacity: 0;
        transition: 0.3s;
      }
    }
    .txt {
      position: relative;
      padding: 1em 0 0 4em;
      &:after {
        content: '';
        display: block;
        border: 1px solid $brand-primary5;
        border-width: 0 0 1px 1px;
        position: absolute;
        left: 1em;
        top: 0;
        bottom: 0.5em;
        width: 1em;
        transition: 0.3s;
      }
    }
    .date, .category {
      display: inline-block;
      vertical-align: middle;
    }
    .date {
      @include fz(18px);
      font-family: $font-family-sans-serif2;
      margin-right: 0.5em;
    }
    .category {
      background: $brand-primary;
      color: #fff;
      padding: 0 0.5em;
      line-height: 1.5;
    }
    .title {
      @include fz(30px);
      line-height: (40 / 30);
      @include multi-line(2);
      margin-top: 0.5em;
    }
  }
  .box:hover {
    .pic {
      border-color: $brand-primary;
      &:after {
        opacity: 1;
      }
    }
    .txt {
      &:after {
        background: $brand-primary;
        border-color: $brand-primary;
      }
    }
    .title {
      color: $brand-primary;
    }
  }
}
.news-detail {
  .date, .category {
    display: inline-block;
    vertical-align: middle;
  }
  .date {
    @include fz(18px);
    font-family: $font-family-sans-serif2;
    margin-right: 0.5em;
  }
  .category {
    background: $brand-primary;
    color: #fff;
    padding: 0 0.5em;
    line-height: 1.5;
  }
  .title {
    @include fz(30px);
    line-height: (40 / 30);
    color: $brand-primary;
    margin: 0.5em 0 1em;
  }
}
.news-editor-txt {
  padding: 1em;
}
.news-gallery {
  margin-top: 3em;
}

@media (min-width: $screen-sm-min) {
  .news-list {
    .box {
      @include clearfix;
      .pic {
        float: left;
        width: 300px;
      }
      .txt {
        margin-left: 300px;
        padding: 1em 0 2.5em 2.25em;
        min-height: 200px;
        &:after {
          border-width: 0 1px 1px 0;
          top: auto;
          bottom: 1em;
          left: 0;
          right: 0.5em;
          width: auto;
          height: 1em;
        }
      }
    }
  }
  .news-detail {
    .title {
      @include fz(30px);
    }
  }
}
@media (min-width: $screen-md-min) {
  .news-detail {
    .title {
      @include fz(36px);
    }
  }
  .news-detail {
    padding-top: 1.5em;
  }
}
@media (min-width: $screen-lg-min) {
  .news-list {
    .box {
      margin-bottom: 4em;
    }
  }
}
@media (min-width: $screen-xl-min) {
}
@media (min-width: $screen-xxl-min) {
  .news-list {
    margin: 0 -20px;
    @include clearfix;
    .item {
      float: left;
      width: 50%;
      padding: 0 20px;
      &:nth-child(2n+1) {
        clear: left;
      }
    }
  }
  .news-list {
    margin: 0 -40px;
    .item {
      padding: 0 40px;
    }
  }
}
