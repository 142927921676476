.pagination {
  @include list-reset;
  display: inline-block;
  vertical-align: middle;
  &-nav {
    text-align: left;
  }
  li {
    float: left;
    margin: 0.25em;
    a {
      display: inline-block;
      position: relative;
      min-width: 2.5em;
      height: 2.5em;
      line-height: 2.5em;
      text-align: center;
    }
  }
  li a:hover,
  li.current a {
    color: #fff;
    background: $brand-primary;
    text-decoration: none;
  }
  .pagination-control {
    a {
      .ic {
        font-size: 0.9em;
        line-height: 1;
      }
    }
  }
}

@media (min-width: $screen-sm-min) {
}
@media (min-width: $screen-md-min) {
  .pagination {
    @include fz(18px);
  }
}
@media (min-width: $screen-lg-min) {
}
