.bt {
  @include btn-reset;
  &:hover {
    text-decoration: none;
  }
  &-default,
  &-primary {
    color: #fff;
    font-weight: 700;
    background: $brand-primary;
    line-height: 2em;
    padding: 0 1em;
    min-width: 7.5em;
    &:hover {
      box-shadow: inset 0 0 10px rgba(#000,0.5);
    }
  }
  &-default {
    background: $brand-primary5;
  }
  &-primary {
    &:hover {
      background: lighten($brand-primary,10%);
    }
  }
}
.bt-box {
  clear: both;
  text-align: center;
  padding: 2em 0 0;
  margin: 0 auto;
  @include clearfix;
}

@media (min-width: $screen-sm-min) {
  .bt {
  }
}
@media (min-width: $screen-md-min) {
}
@media (min-width: $screen-lg-min) {
}
