.anime {
  visibility: hidden;
  &.anime-run {
    visibility: visible;
  }
}
.banner-txt.anime-run {
  @include fadeInUp($duration:1s,$delay:0.1s);
}
.index-hot-content .box.anime-run,
.index-news-slider .box.anime-run {
  @include fadeIn($duration:1s,$delay:0.25s);
}
.about-layout {
  .brand-title.anime-run {
    @include fadeIn($duration:1s,$delay:0.1s);
  }
  .deco.anime-run {
    @include fadeIn($duration:1s,$delay:0.2s);
  }
  .txt.anime-run {
    @include fadeIn($duration:1s,$delay:0.3s);
  }
  .pic.anime-run {
    @include fadeIn($duration: 0.5s);
    @for $i from 1 through 8 {
      &:nth-child(#{$i}) {
        animation-delay: #{$i * 0.15}s;
      }
    }
  }
}
.contact-layout {
  &.anime-run {
    @include fadeIn($duration:1s,$delay:0.2s);
  }
}
.hot-list,
.classic-list,
.news-list {
  .item.anime-run {
    @include fadeIn($duration: 1s);
    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        animation-delay: #{$i * 0.15}s;
      }
    }
  }
}

@media screen and (min-width: $screen-sm-min)  {
}
@media screen and (min-width: 1440px)  {

}
