.index-body {
  background: url('../img/pattern.jpg');
}
.index-banner {
  .item {
    vertical-align: top;
  }
  .pic {
    img {
      width: 100%;
    }
    .img {
      background: no-repeat 50% 50%;
      background-size: cover;
    }
    .m {
      &:before {
        content: '';
        display: block;
        padding-top: percentage($number: 1052 / 750);
      }
    }
    .pc {
      display: none;
    }
  }
  .txt {
    position: relative;
    padding: 2em 1.2em 1.5em;
    border: 1px solid $brand-primary5;
    border-width: 1px 0 0;
    font-family: $font-family-custom;
    font-weight: 300;
    &::before,
    &::after {
      content: '';
      display: block;
      background: url('../img/dot.png') no-repeat;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 3.5em;
      right: 4em;
      pointer-events: none;
    }
    &::after {
      display: none;
    }
  }
  .title,
  .desc {
    position: relative;
  }
  .title {
    color: $brand-primary;
    @include fz(27px);
    font-weight: 500;
    line-height: 1.5;
    margin: 0 0 0.25em;
  }
  .desc {
    @include fz(18px);
    line-height: (27 / 18);
    margin-bottom: 1em;
  }
  .more {
    color: $brand-primary;
    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      border-top: 1px solid $brand-primary;
      margin-right: 0.5em;
      width: 6.25em;
    }
  }
}


@media (min-width: $screen-sm-min) {
  .index-banner {
    .pic {
      .pc {
        display: block;
        &::before {
          content: '';
          display: block;
          padding-top: percentage($number: 644 / 1160);
        }
      }
      .m {
        display: none;
      }
    }
    .txt {
      padding-left: 2.5em;
      padding-right: 2.5em;
    }
  }
}
@media (min-width: $screen-md-min) {
  .index-banner {
    margin-left: 320px;
    border-left: 1px solid $brand-primary5;
    position: relative;
    z-index: 10;
    &:before,
    &:after {
      content: '';
      display: block;
      background: url('../img/dot.png') no-repeat;
      width: 20px;
      height: 20px;
      position: absolute;
      left: -5em;
      pointer-events: none;
    }
    &:before {
      top: 3.5em;
    }
    &:after {
      bottom: 3.5em;
    }
  }
  .index-body {
    .wrapper {
      padding-top: 0;
    }
    .header {
      position: static;
      background: url('../img/index_header_bg.jpg') no-repeat 50% 100%;
      height: 217px;
    }
    .nav {
      position: absolute;
      z-index: 100;
      top: 217px;
      left: 0;
      right: auto;
      bottom: auto;
      width: 320px;
      height: 500px;
      margin-left: 0;
      &-inner {
        padding-top: 8em;
      }
      .menu {
        > li {
          display: block;
          margin: 0;
          > a {
            display: inline-block;
            padding-left: 62px;
            padding-right: 3px;
            &::before {
              left: 28px;
            }
            .tw {
              margin-right: 0.5em;
            }
            .en {
              display: inline-block;
            }
          }
        }
      }
    }
    .footer {
      background: url('../img/index_footer_bg.jpg');
      color: $text-color;
      border-top: 1px solid $brand-primary5;
      padding-top: 100px;
      .container {
        justify-content: flex-start;
        margin-left: 0;
      }
      &-contact {
        margin-left: 2em;
        margin-right: 3em;
        a {
          .ic {
            color: $brand-primary;
          }
          &:hover {
            color: $brand-primary;
          }
        }
      }
      &-link {
        .social {
          color: inherit;
          .ic {
            color: $brand-primary;
          }
          &:hover {
            color: $brand-primary;
          }
        }
      }
    }
  }
}
@media (min-width: $screen-lg-min) {
  .index-banner {
    .box {
      display: flex;
    }
    .pic {
      flex: 1;
      .pc {
        height: 100%;
        &:before {
          padding-top: 0;
        }
      }
    }
    .txt {
      flex: 0 0 240px;
      border-width: 0 0 0 1px;
      padding: 8em 5em 7em 4em;
      &-inner {
        position: relative;
        padding-bottom: 3em;
        min-height: 420px;
        writing-mode: vertical-rl;
      }
      &::before,
      &::after {
        display: block;
        right: auto;
        left: 3.5em;
      }
      &::before {
        top: 3.5em;
      }
      &::after {
        top: auto;
        bottom: 3.5em;
      }
    }
    .title {
      @include fz(30px);
    }
    .desc {
      margin-right: 0.5em;
    }
    .more {
      writing-mode: horizontal-tb;
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      text-align: center;
      padding: 0.2em;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        width: 100%;
      }
      &:hover {
        color: #fff;
        background: $brand-primary;
        text-decoration: none;
      }
    }
  }
}
@media (min-width: $screen-xxl-min) {
  .index-banner {
    margin-left: percentage($number: 435 / 1920);
    .txt {
      flex: 0 0 percentage($number: 324 / 1482);
    }
  }
}
