.work-deco {
    max-width: 80%;
}
.work-form {
    max-width: 650px;
    margin: 0 auto;
    .field-btns {
        padding: 0;
        margin-bottom: 20px;
        .bt-login {
            display: block;
            width: 100%;
            margin: 0;
            @include fz(20px);
            font-weight: 700;
            text-align: center;
            background: url('../img/work_login_bg.jpg') no-repeat 50% 50%;
            background-size: cover;
            line-height: 50px;
            small {
                display: inline-block;
                @include fz(24,30);
                margin-left: 0.5em;
            }
            &:hover {
                box-shadow: inset 0 0 10px rgba(#000, 0.5);
            }
        }
    }
    .reminder {
        margin: 1.5em 0;
    }
}
.work-deco {
    margin-top: 10%;
}
.work-title {
    text-align: right;
    color: $brand-primary;
    font-weight: 700;
    @include fz(30px);
    line-height: 1.5;
    position: relative;
    padding: 0 1.5em;
    margin: 0 0 1em;
    &:after {
        content: '';
        display: block;
        width: 1em;
        border-top: 1px solid $brand-primary6;
        position: absolute;
        right: 0;
        top: 0.75em;
    }
    small {
        display: block;
        @include fz(20,30);
        font-weight: 400;
        margin-top: 0.15em;
    }
}
.work-list {
    .item {
        border-bottom: 1px solid $brand-primary6;
        padding-bottom: 2em;
        margin-bottom: 2em;
    }
    .box {
        .pic {
            display: inline-block;
            vertical-align: top;
            border: 1px solid $brand-primary6;
            position: relative;
            margin-bottom: 1em;
            &:after {
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              background: rgba($brand-primary,0.5) url('../img/more.png') no-repeat 50% 50%;
              opacity: 0;
              transition: 0.3s;
            }
        }
        .date, .more {
            display: inline-block;
            vertical-align: bottom;
        }
        .date {
            position: relative;
            padding-right: 30px;
            padding-bottom: 0.15em;
            font-family: $font-family-sans-serif2;
            margin-right: 0.5em;
            &::before {
                content: '';
                border: 1px solid $brand-primary6;
                border-width: 0 1px 1px 0;
                position: absolute;
                right: 0;
                left: -40px;
                bottom: 0;
                height: 22px;
            }
        }
        .more {
            color: #fff;
            background: $brand-primary;
            line-height: 22px;
            width: 82px;
            text-align: center;
        }
        .txt {
            padding-left: 40px;
            padding-right: 20px;
        }
        .title {
            color: $brand-primary;
            font-weight: 700;
            @include fz(30px);
            line-height: (40 / 30);
            @include one-line;
            margin: 0.6em 0 0.4em;
        }
        .desc {
            line-height: (30 / 18);
            @include multi-line(2);
        }
    }
    .box:hover {
      .pic {
        border-color: $brand-primary;
        &:after {
          opacity: 1;
        }
      }
    }
}
.work-detail {
    .date, .category {
      display: inline-block;
      vertical-align: middle;
    }
    .date {
      @include fz(18px);
      font-family: $font-family-sans-serif2;
      margin-right: 0.5em;
    }
    .category {
      background: $brand-primary;
      color: #fff;
      padding: 0 0.5em;
      line-height: 1.5;
    }
    .title {
      @include fz(30px);
      line-height: (40 / 30);
      color: $brand-primary;
      margin: 0.5em 0 1em;
    }
}
.work-pics {
    display: flex;
    margin: 0 -4px;
    .pic {
        padding: 4px;
    }
}

@media (min-width: $screen-sm-min) {
    .work-form {
        .reminder {
            margin-top: 0;
        }
        .form-box {
            position: relative;
            padding: 0 230px 1px 0;
        }
        .field-btns {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 185px;
            .bt-login {
                height: 100%;
                line-height: 1.5;
                small {
                    margin-left: 0;
                    display: block;
                }
            }
        }
    }
    .work-list {
        .box {
            @include clearfix;
            .pic {
                float: left;
                width: 300px;
            }
            .txt {
                margin-left: 300px;
                padding-top: 1em;
            }
        }
    }
}
@media (min-width: $screen-md-min) {
}
@media (min-width: $screen-lg-min) {
    .work-main {
        position: relative;
    }
    .work-deco {
        width: percentage($number: 850 / 1920);
        position: absolute;
        top: 52%;
        left: 0;
        transform: translateY(-50%);
        margin: 0;
    }
    .work-form {
        padding: 3em 0;
        margin-left: percentage($number: 1000 / 1920);
    }
}
@media (min-width: $screen-xl-min) {
}
@media (min-width: $screen-xxl-min) {
    .work-form {
        padding: 6em 0;
    }
}